<!--分销员申请页面，需要勾选同意规则-->
<template>
    <div class="background">
        <div class="background2">
            <div class="apply-wrapper">
                <div class="apply-body-wrapper">
                    <img src="https://seetop-1257860468.file.myqcloud.com/html/distributor/enroll_button.png" class="apply-button" @click="join">
                    <div class="apply-check-wrapper">
                        <p v-bind:class="[ isAgree ? 'apply-check-ok' : 'apply-check-no' ]" @click="checkRule"></p>
                        <router-link to="/distribution/rule">
                            <p class="apply-rule">我已阅读并同意一碗福州分销细则</p>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { globalConfig } from '../../utils/mixin'

  export default {
    name: 'Apply',
    data () {
      return {
        isAgree: true,
        channel: ''
      }
    },
    mixins: [globalConfig],
    methods: {
      checkRule: function () {
        this.isAgree = !this.isAgree
      },
      join: function () {
        if (this.isAgree) {
          this.axios.post(this.apiUrl + 'mall/distributor/joinDistributor', {
            channel: this.channel
          }).then((response) => {
            const that = this
            const data = response.data
            if (data.status === 1) {
              this.$dialog.alert({
                title: '标题',
                message: '提交成功，请耐心等待'
              }).then(() => {
                this.$router.replace('/user')
              })
            } else if (data.status === -1) {
              this.$toast({
                message: '请先前往绑定',
                onClose: function () {
                  that.$router.push('/bind?url=' + window.location.href)
                }
              })
            } else {
              this.$toast(data.msg)
            }
          })
        } else {
          this.$toast('请先勾选分销规则')
        }
      }
    },
    created () {
      this.channel = this.$route.params.channel
      console.log(window.location.href)
    }
  }
</script>

<style scoped>
    .background{
        background: url("https://seetop-1257860468.file.myqcloud.com/html/distributor/enroll_bg1.jpg") no-repeat;
        background-size: 100vw 100vh;
    }
    .background2{
        width: 100vw;
        height: 100vh;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/distributor/enroll_banner01.png") no-repeat;
        background-size: 100%;
    }
    .apply-wrapper {
        padding-top: 55vw;
    }
    .apply-body-wrapper{
        width: 90vw;
        height: 100vw;
        margin: 0 5vw 0 5vw;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/distributor/enroll_bg2.png") no-repeat;
        background-size: 100%;
    }
    .apply-button {
        width: 60vw;
        margin: 45vw 15vw 0 15vw;
    }
    .apply-check-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .apply-check-no{
        width: 4vw;
        height: 4vw;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/distributor/icon_enroll1.png") no-repeat;
        background-size: 100% 100%;
    }
    .apply-check-ok{
        width: 4vw;
        height: 4vw;
        background: url("https://seetop-1257860468.file.myqcloud.com/html/distributor/icon_enroll2.png") no-repeat;
        background-size: 100% 100%;
    }
    .apply-rule{
        font-size: 3.2vw;
        font-weight: 600;
        color: #ffe4b7;
        margin:0 2vw;
    }
</style>
